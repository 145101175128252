import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import CatalogDownloadListSK from "../../../../components/CatalogDownload/CatalogDownloadListSK";
import CatalogDownloadListen from "../../../../components/CatalogDownload/CatalogDownloadList-en";
import { LocaleContext } from "../../../../components/layout";

const Sk = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { catalogDownloadSK, catalogDownloadSK2 } = useTranslations();

	//正誤表・表紙・カテゴリ
	const CatalogList2 = [
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/hyo01.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/hyo01.pdf",

			title_name: "表紙",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i01.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i01.pdf",

			title_name: "i.01",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i02.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i02.pdf",

			title_name: "i.02",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i03.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i03.pdf",

			title_name: "i.03",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i04.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i04.pdf",

			title_name: "i.04",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i05.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i05.pdf",

			title_name: "i.05",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i06.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i06.pdf",

			title_name: "i.06",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i07.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i07.pdf",

			title_name: "i.07",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i08.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i08.pdf",

			title_name: "i.08",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/i09.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/i09.pdf",

			title_name: "i.09",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P000.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P000.pdf",

			title_name: "P.000",
		},
	];

	//内容ページ 1P〜50P
	const CatalogList3 = [
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P001.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P001.pdf",

			title_name: "P.001",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P002.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P002.pdf",

			title_name: "P.002",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P003.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P003.pdf",

			title_name: "P.003",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P004.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P004.pdf",

			title_name: "P.004",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P005.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P005.pdf",

			title_name: "P.005",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P006.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P006.pdf",

			title_name: "P.006",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P007.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P007.pdf",

			title_name: "P.007",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P008.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P008.pdf",

			title_name: "P.008",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P009.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P009.pdf",

			title_name: "P.009",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P010.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P010.pdf",

			title_name: "P.010",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P011.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P011.pdf",

			title_name: "P.011",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P012.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P012.pdf",

			title_name: "P.012",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P013.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P013.pdf",

			title_name: "P.013",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P014.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P014.pdf",

			title_name: "P.014",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P015.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P015.pdf",

			title_name: "P.015",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P016.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P016.pdf",

			title_name: "P.016",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P017.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P017.pdf",

			title_name: "P.017",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P018.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P018.pdf",

			title_name: "P.018",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P019.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P019.pdf",

			title_name: "P.019",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P020.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P020.pdf",

			title_name: "P.020",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P021.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P021.pdf",

			title_name: "P.021",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P022.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P022.pdf",

			title_name: "P.022",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P023.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P023.pdf",

			title_name: "P.023",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P024.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P024.pdf",

			title_name: "P.024",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P025.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P025.pdf",

			title_name: "P.025",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P026.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P026.pdf",

			title_name: "P.026",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P027.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P027.pdf",

			title_name: "P.027",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P028.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P028.pdf",

			title_name: "P.028",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P029.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P029.pdf",

			title_name: "P.029",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P030.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P030.pdf",

			title_name: "P.030",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P031.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P031.pdf",

			title_name: "P.031",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P032.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P032.pdf",

			title_name: "P.032",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P033.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P033.pdf",

			title_name: "P.033",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P034.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P034.pdf",

			title_name: "P.034",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P035.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P035.pdf",

			title_name: "P.035",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P036.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P036.pdf",

			title_name: "P.036",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P037.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P037.pdf",

			title_name: "P.037",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P038.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P038.pdf",

			title_name: "P.038",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P039.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P039.pdf",

			title_name: "P.039",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P040.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P040.pdf",

			title_name: "P.040",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P041.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P041.pdf",

			title_name: "P.041",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P042.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P042.pdf",

			title_name: "P.042",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P043.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P043.pdf",

			title_name: "P.043",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P044.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P044.pdf",

			title_name: "P.044",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P045.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P045.pdf",

			title_name: "P.045",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P046.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P046.pdf",

			title_name: "P.046",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P047.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P047.pdf",

			title_name: "P.047",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P048.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P048.pdf",

			title_name: "P.048",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P049.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P049.pdf",

			title_name: "P.049",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P050.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P050.pdf",

			title_name: "P.050",
		},
	];

	//内容ページ 51P〜100P
	const CatalogList4 = [
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P051.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P051.pdf",

			title_name: "P.051",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P052.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P052.pdf",

			title_name: "P.052",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P053.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P053.pdf",

			title_name: "P.053",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P054.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P054.pdf",

			title_name: "P.054",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P055.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P055.pdf",

			title_name: "P.055",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P056.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P056.pdf",

			title_name: "P.056",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P057.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P057.pdf",

			title_name: "P.057",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P058.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P058.pdf",

			title_name: "P.058",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P059.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P059.pdf",

			title_name: "P.059",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P060.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P060.pdf",

			title_name: "P.060",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P061.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P061.pdf",

			title_name: "P.061",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P062.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P062.pdf",

			title_name: "P.062",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P063.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P063.pdf",

			title_name: "P.063",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P064.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P064.pdf",

			title_name: "P.064",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P065.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P065.pdf",

			title_name: "P.065",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P066.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P066.pdf",

			title_name: "P.066",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P067.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P067.pdf",

			title_name: "P.067",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P068.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P068.pdf",

			title_name: "P.068",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P069.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P069.pdf",

			title_name: "P.069",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P070.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P070.pdf",

			title_name: "P.070",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P071.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P071.pdf",

			title_name: "P.071",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P072.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P072.pdf",

			title_name: "P.072",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P073.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P073.pdf",

			title_name: "P.073",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P074.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P074.pdf",

			title_name: "P.074",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P075.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P075.pdf",

			title_name: "P.075",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P076.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P076.pdf",

			title_name: "P.076",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P077.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P077.pdf",

			title_name: "P.077",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P078.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P078.pdf",

			title_name: "P.078",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P079.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P079.pdf",

			title_name: "P.079",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P080.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P080.pdf",

			title_name: "P.080",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P081.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P081.pdf",

			title_name: "P.081",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P082.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P082.pdf",

			title_name: "P.082",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P083.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P083.pdf",

			title_name: "P.083",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P084.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P084.pdf",

			title_name: "P.084",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P085.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P085.pdf",

			title_name: "P.085",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P086.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P086.pdf",

			title_name: "P.086",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P087.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P087.pdf",

			title_name: "P.087",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P088.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P088.pdf",

			title_name: "P.088",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P089.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P089.pdf",

			title_name: "P.089",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P090.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P090.pdf",

			title_name: "P.090",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P091.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P091.pdf",

			title_name: "P.091",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P092.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P092.pdf",

			title_name: "P.092",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P093.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P093.pdf",

			title_name: "P.093",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P094.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P094.pdf",

			title_name: "P.094",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P095.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P095.pdf",

			title_name: "P.095",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P096.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P096.pdf",

			title_name: "P.096",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P097.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P097.pdf",

			title_name: "P.097",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P098.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P098.pdf",

			title_name: "P.098",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P099.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P099.pdf",

			title_name: "P.099",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P100.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P100.pdf",

			title_name: "P.100",
		},
	];

	//内容ページ 100P〜137P
	const CatalogList5 = [
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P101.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P101.pdf",

			title_name: "P.101",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P102.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P102.pdf",

			title_name: "P.102",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P103.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P103.pdf",

			title_name: "P.103",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P104.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P104.pdf",

			title_name: "P.104",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P105.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P105.pdf",

			title_name: "P.105",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P106.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P106.pdf",

			title_name: "P.106",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P107.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P107.pdf",

			title_name: "P.107",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P108.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P108.pdf",

			title_name: "P.108",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P109.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P109.pdf",

			title_name: "P.109",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P110.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P110.pdf",

			title_name: "P.110",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P111.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P111.pdf",

			title_name: "P.111",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P112.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P112.pdf",

			title_name: "P.112",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P113.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P113.pdf",

			title_name: "P.113",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P114.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P114.pdf",

			title_name: "P.114",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P115.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P115.pdf",

			title_name: "P.115",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P116.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P116.pdf",

			title_name: "P.116",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P117.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P117.pdf",

			title_name: "P.117",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P118.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P118.pdf",

			title_name: "P.118",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P119.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P119.pdf",

			title_name: "P.119",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P120.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P120.pdf",

			title_name: "P.120",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P121.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P121.pdf",

			title_name: "P.121",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P122.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P122.pdf",

			title_name: "P.122",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P123.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P123.pdf",

			title_name: "P.123",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P124.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P124.pdf",

			title_name: "P.124",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P125.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P125.pdf",

			title_name: "P.125",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P126.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P126.pdf",

			title_name: "P.126",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P127.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P127.pdf",

			title_name: "P.127",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P128.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P128.pdf",

			title_name: "P.128",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P129.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P129.pdf",

			title_name: "P.129",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P130.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P130.pdf",

			title_name: "P.130",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P131.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P131.pdf",

			title_name: "P.131",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P132.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P132.pdf",

			title_name: "P.132",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P133.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P133.pdf",

			title_name: "P.133",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P134.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P134.pdf",

			title_name: "P.134",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P135.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P135.pdf",

			title_name: "P.135",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P136.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P136.pdf",

			title_name: "P.136",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/P137.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/P137.pdf",

			title_name: "P.137",
		},
	];

	//裏表紙・製品番号
	const CatalogList6 = [
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/ib01.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/ib01.pdf",

			title_name: "i.01",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/ib02.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/ib02.pdf",

			title_name: "i.02",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/ib03.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/ib03.pdf",

			title_name: "i.03",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/ib04.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/ib04.pdf",

			title_name: "i.04",
		},

		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/ib05.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/ib05.pdf",

			title_name: "i.05",
		},
		{
			alt: "SK総合カタログ 2020～2021",
			image: "/asset/catalog/sogo2020-21-ja/urahyo01.jpg",
			link:
				"https://static.sksato.co.jp/leaflet/ja/sogo2020-21-ja/urahyo01.pdf",

			title_name: "裏表紙",
		},
	];

	//English language
	const CatalogList2en = [
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621hyo1.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_hyo1.pdf",
			title_name: "Front Cover",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_1.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_1.pdf",
			title_name: "Corporate Profile",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_2.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_2.pdf",
			title_name: "Table of Contents",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_3.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_3.pdf",
			title_name: "Page3",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_4.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_4.pdf",
			title_name: "Page4",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_5.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_5.pdf",
			title_name: "Page5",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_6.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_6.pdf",
			title_name: "Page6",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_7.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_7.pdf",
			title_name: "Page7",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_8.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_8.pdf",
			title_name: "Page8",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_9.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_9.pdf",
			title_name: "Page9",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_10.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_10.pdf",
			title_name: "Page10",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_11.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_11.pdf",
			title_name: "Page11",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_12.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_12.pdf",
			title_name: "Page12",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_13.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_13.pdf",
			title_name: "Page13",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_14.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_14.pdf",
			title_name: "Page14",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_15.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_15.pdf",
			title_name: "Page15",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_16.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_16.pdf",
			title_name: "Page16",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_17.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_17.pdf",
			title_name: "Page17",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_18.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_18.pdf",
			title_name: "Page18",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_19.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_19.pdf",
			title_name: "Page19",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_20.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_20.pdf",
			title_name: "Page20",
		},

		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_21.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_21.pdf",
			title_name: "Page21",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_22.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_22.pdf",
			title_name: "Page22",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_23.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_23.pdf",
			title_name: "Page23",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_24.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_24.pdf",
			title_name: "Page24",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_25.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_25.pdf",
			title_name: "Page25",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_26.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_26.pdf",
			title_name: "Page26",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_27.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_27.pdf",
			title_name: "Page27",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_28.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_28.pdf",
			title_name: "Page28",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_29.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_29.pdf",
			title_name: "Page29",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_30.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_30.pdf",
			title_name: "Page30",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_31.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_31.pdf",
			title_name: "Page31",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_32.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_32.pdf",
			title_name: "Page32",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_33.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_33.pdf",
			title_name: "Page33",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_34.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_34.pdf",
			title_name: "Page34",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_35.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_35.pdf",
			title_name: "Page35",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_36.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_36.pdf",
			title_name: "Page36",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_37.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_37.pdf",
			title_name: "Page37",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_38.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_38.pdf",
			title_name: "Page38",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_39.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_39.pdf",
			title_name: "Page39",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_40.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_40.pdf",
			title_name: "Page40",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_41.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_41.pdf",
			title_name: "Page41",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_42.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_42.pdf",
			title_name: "Page42",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_43.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_43.pdf",
			title_name: "Page43",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_44.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_44.pdf",
			title_name: "Page44",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_45.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_45.pdf",
			title_name: "Page45",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_46.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_46.pdf",
			title_name: "Page46",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_47.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_47.pdf",
			title_name: "Page47",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_48.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_48.pdf",
			title_name: "Page48",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_49.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_49.pdf",
			title_name: "Page49",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_50.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_50.pdf",
			title_name: "Page50",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_51.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_51.pdf",
			title_name: "Page51",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621_52.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_52.pdf",
			title_name: "Page52",
		},
		{
			alt: "SK General Catalog 2020-2021",
			image: "/asset/catalog/sogo2020-21-en/2021_621hyo2.jpg",
			link:
				"https://static.sksato.co.jp/corporate/catalog/sogo2020-21-en/2021_621_hyo2.pdf",
			title_name: "Back Cover",
		},
	];

	const { locale } = React.useContext(LocaleContext);
	const CatalogDownloadJa = () => {
		return (
			<div className="content-container catalog-download catalog-downloadSK">
				<div>
					<p>
						製品カタログは、PDFファイルにて提供しております。
						<br />
					</p>
				</div>
				<div>
					<p className="important-text">
						※ダウンロードいただけるパンフレットは作成時点でのデータが元になっております。
						<br />
						価格・消費税率・仕様等は現行と異なることがありますのでご注意ください。
					</p>
				</div>
				<div>
					<h2>
						表紙・インデックス（ページ順）・
						<br class="sp" />
						インフォメーション
					</h2>
					<ul className="catalog-ul flex wrap">
						{CatalogList2.map((CatalogItem2, index) => {
							return (
								<CatalogDownloadListSK
									key={index}
									image={CatalogItem2.image}
									alt={CatalogItem2.alt}
									link={CatalogItem2.link}
									title_name={CatalogItem2.title_name}
								/>
							);
						})}
					</ul>

					<h2>P.1～P.50</h2>
					<ul className="catalog-ul flex wrap">
						{CatalogList3.map((CatalogItem3, index) => {
							return (
								<CatalogDownloadListSK
									key={index}
									image={CatalogItem3.image}
									alt={CatalogItem3.alt}
									link={CatalogItem3.link}
									title_name={CatalogItem3.title_name}
								/>
							);
						})}
					</ul>

					<h2>P.51～P.100</h2>
					<ul className="catalog-ul flex wrap">
						{CatalogList4.map((CatalogItem4, index) => {
							return (
								<CatalogDownloadListSK
									key={index}
									image={CatalogItem4.image}
									alt={CatalogItem4.alt}
									link={CatalogItem4.link}
									title_name={CatalogItem4.title_name}
								/>
							);
						})}
					</ul>

					<h2>P.101～P.137</h2>
					<ul className="catalog-ul flex wrap">
						{CatalogList5.map((CatalogItem5, index) => {
							return (
								<CatalogDownloadListSK
									key={index}
									image={CatalogItem5.image}
									alt={CatalogItem5.alt}
									link={CatalogItem5.link}
									title_name={CatalogItem5.title_name}
								/>
							);
						})}
					</ul>

					<h2>裏表紙・インデックス（製品番号順）</h2>
					<ul className="catalog-ul flex wrap">
						{CatalogList6.map((CatalogItem6, index) => {
							return (
								<CatalogDownloadListSK
									key={index}
									image={CatalogItem6.image}
									alt={CatalogItem6.alt}
									link={CatalogItem6.link}
									title_name={CatalogItem6.title_name}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};
	const CatalogDownloadEn = () => {
		return (
			<div className="content-container catalog-download">
				<div>
					<p> GENERAL CATALOG No. 621</p>
				</div>
				<div>
					<p className="important-text">
						* All information in the catalog below is as of January, 2023.
						<br />
						Note that the specifications may differ from the current ones.
					</p>
				</div>
				<div>
					<h2> SK General Catalog Page-by-page Download</h2>
					<p>
						Each page of the SK General Catalog can be viewed and downloaded below.
					</p>

					<ul className="catalog-ul flex wrap">
						{CatalogList2en.map((CatalogItem2en, index) => {
							return (
								<CatalogDownloadListen
									key={index}
									image={CatalogItem2en.image}
									alt={CatalogItem2en.alt}
									link={CatalogItem2en.link}
									title={CatalogItem2en.title}
									size={CatalogItem2en.size}
									title_name={CatalogItem2en.title_name}
									catalog_list_info_page={CatalogItem2en.catalog_list_info_page}
									catalog_list_info_date={CatalogItem2en.catalog_list_info_date}
									catalog_list_info_update={
										CatalogItem2en.catalog_list_info_update
									}
								/>
							);
						})}
					</ul>
				</div>
			</div>
		);
	};
	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						{catalogDownloadSK}
						<br class="sp"></br>
						{catalogDownloadSK2}
					</h2>
					{locale === "ja" ? <CatalogDownloadJa /> : <CatalogDownloadEn />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Sk;
